import React from "react";
import Modal from './Modal'
import { MdOutlineClose } from "react-icons/md";
const Brochures = ({isOpen, handleClose}) => {

  return (
    <div>   <Modal isOpen={isOpen}>
    <div className="modalHeader">
                <h3>Get our brochures and a quote</h3>
                     <button onClick={handleClose}>
                       <MdOutlineClose size={25} />
                     </button>
                   </div>
                   <div className="modalBody">
                   <from>
           <div className="form-group">
             <label>Name</label>
             <input type="text" className="form-control"/>
           </div>
           <div className="form-group">
             <label>Email</label>
             <input type="text" className="form-control"/>
           </div>
           <div className="form-group">
             <label>Contact </label>
             <input type="text" className="form-control"/>
           </div>
           <div className="form-group">
             <label>Institution </label>
             <input type="text" className="form-control"/>
           </div>
           <div className="form-group">
             <label>Query  </label>
             <textarea className="form-control"></textarea>
           </div>
           <button  className="primaryButton ">Download Now</button>
       </from>
                   </div>
               
     
 </Modal></div>
  )
}

export default Brochures