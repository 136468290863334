import React, { Fragment, useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'


import EnquiryForm from './EnquiryForm';
const Header = () => {
    const [isOpen, setOpen] = useState(false);
    const [sticky, setSticky] = useState("");

    // on render, set listener
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [isOpen]);

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 150 ? "is-sticky" : "";
        setSticky(stickyClass);
    };

    return (
        <Fragment>
            <header className={isOpen ? '' : sticky}>
                <div className='container headerContainer'>
                    <div className='brand'>
                        <a href='/'>
                            <img src='/images/logo.png' alt="brand" />
                        </a>
                    </div>
                    <div className='menu'>
                    <nav className={`navigation ${isOpen && 'open'}`}>
                        <ul className='navList'>
                        <li> <NavLink to={'/about'} onClick={()=>setOpen(false)}>About Us</NavLink></li>
                        <li className='megaMenu'> <NavLink to={'/our-program'} onClick={()=>setOpen(false)}>Our Programs</NavLink>
                           <ul className='submenu'>
                               <li> <NavLink to={'/our-program/france-internship-program'} onClick={()=>setOpen(false)}>France Internships</NavLink></li>
                               <li> <NavLink to={'/our-program/mauritius-internship-program'} onClick={()=>setOpen(false)}>Mauritius Internships</NavLink></li>
                               <li> <NavLink to={'/our-program/netherlands-work-permit-program'} onClick={()=>setOpen(false)}>The Netherlands Work Permit</NavLink></li>
                               <li> <NavLink to={'/our-program/italy-work-permit-program'} onClick={()=>setOpen(false)}>Italy Work Permit</NavLink></li>
                               <li> <NavLink to={'/our-program/gcc-internship-job-placement-program'} onClick={()=>setOpen(false)}>Gulf Countries</NavLink></li>
                               <li> <NavLink to={'/our-programs'} onClick={()=>setOpen(false)}>The USA</NavLink></li>
                           </ul>
                        
                        </li>
                        <li className='megaMenu'> <NavLink to={'/why-choose-us'} onClick={()=>setOpen(false)}>Why Choose Us</NavLink>
                           <ul className='submenu'>
                               <li> <NavLink to={'/why-choose-us/language-courses'} onClick={()=>setOpen(false)}>Language Courses</NavLink></li>
                               <li> <NavLink to={'/why-choose-us/fit-insideout-program'} onClick={()=>setOpen(false)}>Fitinsideout Program</NavLink></li>
                               <li> <NavLink to={'/why-choose-us/counseling-therapy-support'} onClick={()=>setOpen(false)}>Counseling & Therapy Support</NavLink></li>
                           </ul>
                        
                        </li>

                        <li> <NavLink to={'/testimonial'} onClick={()=>setOpen(false)}>Testimonial</NavLink></li>
                        <li> <NavLink to={'/blog'} onClick={()=>setOpen(false)}>Blogs</NavLink></li>
                        <li> <NavLink to={'/faq'} onClick={()=>setOpen(false)}>Faq</NavLink></li>
                        <li> <NavLink to={'/contact-us'} onClick={()=>setOpen(false)}>Contact Us</NavLink></li>
                        </ul>
                    </nav>
                  
                    <Link className='book-btn' to={'/book-appointment'}>Book Appointment</Link>
                    
                    </div>
                   
                    <button className={`navToggle ${isOpen && 'open'}`} onClick={() => setOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </header>
             <EnquiryForm/>
        </Fragment>


    )
}

export default Header