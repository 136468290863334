import React, { Fragment, useState, useEffect } from "react";
import { Apis } from "../config";
import { Link } from "react-router-dom";
import MeetOurTeam from "../components/common/MeetOurTeam";
import BrandLogos from "../components/common/BrandLogos";
const About = () => {
  const [isLoading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [aboutContent, setAboutContent] = useState(null);

  const getData = async () => {
    try {
      const res = await Promise.all([
        fetch(Apis.GetTeamMember),
        fetch(Apis.GetAbout),
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      if (data) {
        if (data[0] && data[0].status) {
          setTeamData(data[0].teamMembers);
        }
        if (data[1] && data[1].status) {
          setAboutContent(data[1].aboutContent);
        }

        setLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <div className="fakeLoader">
          <div className="lod-1">
            <div className="lod-2"></div>
          </div>
        </div>
      ) : (
        <div className="innerPageWrapper">
          <div className="page-banner">
            <div className="container">
              <div className="row align-center">
                <div className="col-md-7">
                  <div className="page-banner-content">
                    <span>OUR WORK</span>
                    <p>
                      Embrace Your Destiny's Calling - Unfold New Horizons and
                      Shape Your Career with Boundless Possibilities!
                    </p>
                    <p>
                      Welcome to Destiny Calling, your passport to a world of
                      transformative internship and job experiences. We
                      specialize in curating life-changing opportunities for
                      hotel management and hospitality students in some of the
                      most exotic corners of the globe, including France,
                      Mauritius, the USA, Gulf countries, and the Netherlands.
                    </p>
                    <p>
                      Our mission? To make your career journey not just a path
                      but an odyssey, filled with unique experiences, valuable
                      skills, and international exposure. Whether you're drawn
                      to the culinary delights of France or the sunny beaches of
                      Mauritius, we're here to ensure you embark on a rewarding
                      adventure.
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="page-banner-img">
                    <img src="/images/about-bnr.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {aboutContent !== null && (
            <div className="innerPageBanner">
              <div className="container">
                <h1>{aboutContent.page_title}</h1>
                <div className="bannerImg">
                  <img
                    src={aboutContent.banner_image}
                    alt="about-banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          )} */}
          <div className="page-content pt-50 pb-0">
            <div className="container ">
              <h3 className="title-3">Our Story</h3>
              <p>
                Having interned ourselves in different countries abroad, we
                realized the profound beauty of international exposure. It's not
                just about shaping our professional careers; it's about shaping
                our very personalities. International experiences introduce us
                to new cultures, ways of living, and a completely different
                world beyond our comfort zones.
              </p>
              <p>
                It's about networking, understanding new possibilities,
                observing ten people doing ten different things, and learning
                how to adapt to diverse environments and work cultures. It's
                about developing independence, navigating unfamiliar territory,
                grasping the importance of financial acumen, and mastering the
                art of presentation – how to speak, talk, and move with
                confidence.
              </p>

              <p>
                We recognized that internships are crucial for personal and
                professional growth. You can't simply learn theory one day and
                take on significant job responsibilities the next. Real-life
                internships, especially in sectors like hospitality and hotel
                management, are vital. They allow you to make mistakes in a
                welcoming environment, learn from them, and evolve.
              </p>
              <p>
                In the hospitality world, you can only perfect a dish like
                lasagna after making it at least 20 times and receiving feedback
                on it 50 times. With this understanding, Destiny Calling was
                born – a place for all hospitality and hotel management
                enthusiasts to learn, intern, and launch their careers
                internationally.
              </p>
              <h5>So, how did we come up with the name?</h5>
              <p>
                {" "}
                Destiny Calling isn't just a company; it's a story of destiny,
                dreams, and unexpected surprises. We've spent years connecting
                young talents with their dream internships, work permits, and
                professional pathways, and along the way, we've discovered
                something remarkable.
              </p>
              <p>
                Life often takes us on paths we didn't anticipate, and that's
                where destiny shines. It has an uncanny way of revealing
                opportunities beyond our imagination. Sometimes, what you think
                you want is just the tip of the iceberg, and destiny has grander
                plans.
              </p>
              <p>
                We've seen countless instances where students set their sights
                on one destination only to find a more magnificent opportunity
                elsewhere.
              </p>
              <p>
                Destiny Calling embodies the belief that one's destiny calls
                them, and we're here to help you answer that call. We stand by
                your side as you step into the unknown, matching you with
                opportunities that align with your dreams and skills, creating
                unforgettable moments on your transformative journey.
              </p>
              <div className="inner-page-section">
                <div className="row align-center">
                  <div className="col-md-5">
                    <div className="inner-img-box">
                      <img src="/images/abt1.png" alt="img" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="inner-content-box">
                      <h2 className="theme-title">Our Vision</h2>
                      <p className="mb-30">
                        Destiny Calling envisions a world where every young
                        individual's true potential is unlocked through
                        transformative internship opportunities. We are the
                        bridge that connects dreams with reality, and we're
                        committed to making it a reality.
                      </p>
                      <h2 className="theme-title">Our Mission</h2>
                      <p className="mb-0">
                        Our mission is simple yet profound: to connect aspiring
                        individuals with meaningful internships that align with
                        their aspirations and unique talents. We do this through
                        personalized support, meticulous matchmaking, and a deep
                        belief in the magic of destiny. Our goal is to unlock
                        doors to endless possibilities for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-page-section pt-0">
                <div className="row align-center">
                  <div className="col-md-7">
                    <div className="inner-content-box">
                      <h3 className="theme-title">OUR CORE VALUES DEFINE US</h3>
                      <p>
                        <strong>Trust </strong> <br /> Our foundation is built
                        on trust, transparency, and accountability. We're your
                        trusted partner in shaping your career.
                      </p>
                      <p>
                        <strong>Innovation</strong> <br /> We go beyond
                        textbooks, offering live workshops for cultural
                        immersion, engaging language tours, and exciting
                        excursions. We're not just about learning; we're about
                        putting that knowledge into practice and embracing
                        global experiences.
                      </p>
                      <p>
                        <strong>Passion</strong> <br /> At Destiny Calling, our
                        team's fervor drives us to go the extra mile. We're
                        genuinely passionate about what we do, and that passion
                        fuels our dedication to your success.
                      </p>
                      <p>
                        <strong>Commitment to Students</strong> <br />
                        We prioritize quality over quantity. By limiting the
                        number of students we work with, we can provide
                        personalized training and unwavering support. Your
                        journey is our journey.
                      </p>
                      <p>
                        At Destiny Calling, we're not just a company; we're the
                        storytellers of your destiny. Let's create your
                        extraordinary narrative together.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="inner-img-box">
                      <img src="/images/abt-2.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <MeetOurTeam teamData={teamData} />
            <BrandLogos title={"HOTELS WE HAVE SUCCESSFULLY PLACED IN"} />
          </div>
          <div
            className="career-section"
            style={{
              backgroundImage: `url('/images/blogThumb.png')`,
            }}
          >
            <div className="container">
              <div className="career-inner">
                <h3>READY TO DIVE INTO THE WORLD OF INTERNATIONAL HOSPITALITY?</h3>
                <Link to="/our-program" className="borderButton">
                  Check out our Programs
                </Link>
              </div>
            </div>
            <div className="careerOverlay"></div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default About;
