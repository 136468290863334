import React, { Fragment } from "react";
import { Link } from "react-router-dom";


const FitInsideoutProgram = () => {
    return (
        <Fragment>
            <div className="innerPageWrapper">
                <div className="innerPageBanner-2">
                    <img
                        src="/images/fit.jpg"
                        alt="about-banner"
                        className="img-fluid"
                    />
                </div>
                <div className="page-content pt-15">
                    <section className="inner-program-sec-1">
                        <div className="container">
                            <h4 className="lang-title ">
                                FitInsideOut Program
                            </h4>
                            <p>
                                Destiny Calling proudly partners with FitInsideOut, providing our students a transformative
                                program to conquer lifestyle challenges encountered during their international assignments.
                                This initiative is meticulously crafted to instill a holistic approach to health, ensuring students
                                possess the knowledge and skills to navigate physical hurdles and fortify mental resilience. </p>

                            <p>Having experienced international transitions ourselves, we intimately understand the shifts in
                                dietary patterns and the challenges of maintaining a healthy lifestyle in unfamiliar territories.
                                FitInsideOut was conceived with the goal of creating a universally adaptable plan, catering to
                                individuals irrespective of their location. It's a comprehensive program designed to thrive
                                anywhere in the world.</p>
                            <p>We recognize the pivotal role a balanced and nourishing diet plays in amplifying productivity and
                                fostering well-being. FitInsideOut is a commitment to supporting students and professionals,
                                empowering them to reach their peak performance by prioritizing their overall health. Join us on
                                a journey of holistic well-being, ensuring you're not just surviving but thriving in every corner of
                                the globe.</p>

                        </div>
                    </section>
                    <section className="inner-program-sec-3 pt-0">
                        <div className="container">

                            <h4 className="lang-title text-center mb-25"> How would this benefit you? </h4>
                            <div className='services'>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        <div className='col-lg-4 mb-25'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/inproved-health.png' alt='' />
                                                <h4>Improved Health & Well-being</h4>
                                                <p className="mb-0">FitInsideOut prioritizes nutrition, which is essential for
                                                    maintaining good health and holistic well-being. By following the program, students can improve
                                                    their dietary habits, leading to better physical and mental health. A balanced and nutritious diet
                                                    can boost energy levels, enhance concentration, and support a strong immune system,
                                                    ultimately enabling students to perform at their best.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/enhanced-performance.png' alt='' />
                                                <h4>Enhanced Performance & Productivity</h4>
                                                <p className="mb-0">Proper nutrition is directly linked to cognitive function
                                                    and productivity. By providing access to nutrition services, FitInsideOut helps students optimize
                                                    their nutritional intake, fueling their bodies and minds for peak performance.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/long-term-healty.png' alt='' />
                                                <h4>Long-term Healthy Habits</h4>
                                                <p className="mb-0">FitInsideOut aims to instill healthy eating habits in students that can
                                                    have long-lasting benefits. By participating in the program, student
                                                    s can develop a better understanding of balanced nutrition, portion control, and mindful eating.</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className=" quote-box">
                        <div className="inner-quote-box">
                            <p className="quote-text">"FitInsideOut has been a game-changer for our students! The program is a holistic guide to lifestyle and has transformed their overall well-being. I've noticed a significant improvement in their energy levels and concentration, allowing them to perform at their best.”</p>
                            <div className="quote-user">
                                <span> - Rashi Gulati</span>
                                <span>Business Development Head, Destiny Calling</span>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </Fragment>
    );
};

export default FitInsideoutProgram;
