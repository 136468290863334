import React, { useState, useEffect } from 'react'
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { MdOutlineClose } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { Formik } from 'formik';
import { GetEnquires } from '../../helpers';
import swal from 'sweetalert';
import Modal from '../models/Modal';
import Cookies from 'universal-cookie';
const EnquiryForm = () => {
    const cookies = new Cookies();
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    useEffect(() => {
       let isFilled = cookies.get('popup_enq');
        setTimeout(() => {
            if(isFilled !== 'done'){
                setOpen(true);
            }
        }, 5000)
    }, []);

    const handleSubmit = async(values, { setSubmitting, resetForm  })=>{
        setLoading(true);
         let resp = await GetEnquires.sendEnquiry(values);
         if(resp && resp.status){
            setOpen(false);
            cookies.set('popup_enq', 'done', { path: '/' });
            swal({
                title: "Good job!",
                text: resp.message,
                icon: "success",
                button: "OK",
              });
            // alert(JSON.stringify(resp, null, 2));
           
            setLoading(false);
            setSubmitting(false);
            resetForm();
           
         }
    }
    const closeModal = () =>{
        setOpen(false);
        cookies.set('popup_enq', 'done', { path: '/' });
    }
   
    return (
        <div>
            {/* <button className='replyBtn' onClick={() => setOpen(true)}><TfiHeadphoneAlt fontSize={23} color='#fff' /></button> */}
            <Modal isOpen={isOpen} closeModal={closeModal} maxWidth={800}>




                <div className="modalContent plr">
                    <button className='md-btn-2' onClick={closeModal}>
                        <MdOutlineClose size={25} />
                    </button>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <img src='/images/contact-pop.jpg' alt='img' className='pop-img' />
                        </div>
                        <div className='col-md-6'>
                            <div className='enqForm'>
                                <h3 className='text-center'>Connect With Us</h3>
                                <Formik
                                    initialValues={{ name: '', email: '', phone: '' }}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.name) {
                                            errors.name = 'Name is required';
                                        }

                                        if (!values.email) {
                                            errors.email = 'Email is required';
                                        } else if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                        ) {
                                            errors.email = 'Invalid email address';
                                        }

                                        if (!values.phone) {
                                            errors.phone = 'Phone is required';
                                        }else if(!/^[0-9]{10}$/i.test(values.phone)){
                                            errors.phone = 'Invalid phone number';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        /* and other goodies */
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Name</label>
                                                <input 
                                                 type='text' 
                                                 className='form-control' 
                                                 name="name" 
                                                 id="name" 
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 value={values.name}
                                                 />
                                                   {errors.name && touched.name && <span className='error-help'>{errors.name}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='email'>Email</label>
                                                <input 
                                                 type='text' 
                                                 className='form-control' 
                                                 name="email" 
                                                 id="email" 
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 value={values.email}
                                                 />
                                                   {errors.email && touched.email && <span className='error-help'>{errors.email}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='phone'>Mobile Number</label>
                                                <input 
                                                 type='text' 
                                                 className='form-control' 
                                                 name="phone" 
                                                 id="phone" 
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 value={values.phone}
                                                 />
                                                   {errors.phone && touched.phone && <span className='error-help'>{errors.phone}</span>}
                                            </div>
                                            <div className='text-center'>
                                                <button type="submit" disabled={isSubmitting}>
                                                            {
                                                                isLoading ? 'Please wait..' : <>
                                                                  <FiSend /> Send
                                                                </>
                                                            }
                                                            
                                                  
                                                </button>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </div>

    )
}

export default EnquiryForm