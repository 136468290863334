import React, { Fragment } from "react";
import { Link } from "react-router-dom";


const LanguageCourses = () => {
    return (
        <Fragment>
            <div className="innerPageWrapper">
                <div className="innerPageBanner-2">
                    <img
                        src="/images/fg.jpg"
                        alt="about-banner"
                        className="img-fluid"
                    />
                </div>
                <div className="page-content pt-15">
                    <section className="inner-program-sec-1">
                        <div className="container">
                            <h4 className="lang-title ">
                                Empowering communication through language mastery
                            </h4>
                            <p>
                                At Destiny Calling, we've ingeniously integrated complimentary language courses into our
                                programs, addressing the distinctive challenges encountered by our students studying abroad.
                                Our language curriculum, currently featuring French with more languages to follow, consists of
                                expertly crafted video modules by our certified professionals. These courses are a prelude for
                                students venturing into new territories, aimed at elevating their language proficiency. </p>

                            <p>Our meticulously designed courses not only provide students with a solid linguistic foundation
                                but also foster adaptability to diverse cultures and environments. Going beyond video modules,
                                we offer weekly live sessions conducted by our trainers. These sessions serve as personalized
                                check-ins to monitor progress, clarify doubts, and fine-tune oral language skills. At Destiny
                                Calling, we believe that mastering the language is key to unlocking a richer and more immersive
                                experience during your stay abroad. </p>

                        </div>
                    </section>
                    <section className="inner-program-sec-3 pt-0">
                        <div className="container">

                            <h4 className="lang-title text-center mb-25"> How can it benefit you? </h4>
                            <div className='services'>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        <div className='col-lg-4 mb-25'>
                                            <div className='service-items item2'>
                                                <img src='/images/icons/recorded-sessions.png' alt='' />
                                                <h4>Recorded Sessions at Your Fingertips</h4>
                                                <p className="mb-0">Access recorded sessions at your convenience, covering vocabulary, grammar, pronunciation, and dialogue practice. Learn at your own pace and revisit key lessons whenever needed.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                            <img src='/images/icons/live-sessions.png' alt='' />
                                                <h4>Live Sessions with Seasoned Experts</h4>
                                                <p className="mb-0">Engage in weekly live sessions with language experts for real-time practice, feedback, and doubt clarification. Interact directly with our instructors to benefit from their expertise and personalized guidance.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                            <img src='/images/icons/tailored-learning.png' alt='' />
                                                <h4>Tailored Learning with Personalized Attention</h4>
                                                <p className="mb-0">Receive individualized guidance and support tailored to your learning needs. Our instructors address specific areas of improvement, ensuring a customized learning experience that aligns with your pace and proficiency level.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                            <img src='/images/icons/progress-tracking.png' alt='' />
                                                <h4>Progress Tracking through Regular Tests</h4>
                                                <p className="mb-0">Gauge your progress with regular tests designed to reinforce your learning. These assessments help you practice language skills, assess understanding, and track improvement over time.</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='service-items item2'>
                                            <img src='/images/icons/cultural-immersion.png' alt='' />
                                                <h4>Cultural Immersion Beyond Language</h4>
                                                <p className="mb-0">Immerse yourself in the culture of the country you're visiting. Our courses go beyond
                                                    language, offering insights into traditions, customs, and local nuances, enhancing your
                                                    understanding and appreciation of the destination. This cultural immersion aspect adds
                                                    an extra layer to your experience, making it truly enriching and unforgettable.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className=" quote-box">
                        <div className="inner-quote-box">
                            <p className="quote-text">"Our Language Courses have been an incredible resource for the students! I have noticed that the recorded sessions helped them learn the foreign language at their own pace”</p>
                            <div className="quote-user">
                                <span>- Aditi Parekh</span>
                                <span>HR, Destiny Calling</span>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </Fragment>
    );
};

export default LanguageCourses;
